.listicle {
  list-style: none;
  padding-left: 0;
  li {
    margin-top: 30px;
  }
}

.TnC-wrapper {
  .user-agreement {
    margin: 50px 0;

    p {
      a {
        color: $info;
        text-decoration: none;

        &:hover {
          color: $info;
        }
      }
    }
    .laws {
      list-style: none;
      padding-left: 0;
    }
  }
}
