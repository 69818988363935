.tooltip {
  .tooltip-arrow {
    &::before {
      border-bottom-color: grey;
    }
  }
  .tooltip-inner {
    @include font(hind, regular);
    font-size: 14px;
    max-width: 500px;
    width: 100%;
    background-color: grey;
    border-radius: 0;

    div {
      font-size: 12px;
    }

    .table {
      color: $white;
      border-collapse: collapse;
      thead {
        text-transform: uppercase;
      }
    }
  }
}
