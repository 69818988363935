.sv-sitemap-wrapper {
  li {
    font-size: 16px;
    line-height: 25px;

    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $info;
      }
    }
  }
}
