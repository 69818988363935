.footer {
  .sv-footer {
    background: linear-gradient(85.14deg, #021333 0.9%, #02235e 99.81%);
    padding: 30px 0;
    .links-title {
      @include font(hind, bold);
      font-size: 17px;
      line-height: 27px;
      color: $white;
      margin-bottom: 5px;
    }

    .footer-links {
      padding-left: 0;
      list-style-type: none;

      li {
        margin-top: 5px;
        a {
          @include font(hind, medium);
          font-size: 17px;
          line-height: 30px;
          color: $white;
          text-decoration: none;

          &:hover {
            @include font(hind, bold);
            color: $white;
          }
        }
      }
    }

    .social-links {
      margin-top: 10px;
      .follow-us {
        @include font(hind, medium);
        font-size: 17px;
        line-height: 30px;
        color: $white;
      }

      .social-icon {
        margin-left: 15px;
        cursor: pointer;
      }
    }
  }

  .copyrights {
    background-color: $dp-primary-green;
    padding: 12px 0;
    p {
      @include font(hind, medium);
      font-size: 17px;
      line-height: 27px;
      color: $white;
      text-align: center;
      margin-bottom: 0;
    }
  }
}

