$white: #ffffff;
$black: #000000;

$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$grays: (
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
);
$primary: #04be45;
$secondary: #0A1632;
$tertiary: #707070;
$info: #008ae0;
$danger: #9e001f;
$warning: #f2994a;
$success: #209542;
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "tertiary": $tertiary,
);
$body-color: #666666;
$body-bg: #fff;
$text-muted: #bdbdbd;
$link-color: #007bff;
$link-hover-color: #007bff;
$font-size-base: 1rem;
$font-weight-base: 400rem;
$h1-font-size: 2.5rem;
$h2-font-size: 2rem;
$h3-font-size: 1.75rem;
$h4-font-size: 1.5rem;
$h5-font-size: 1.25rem;
$h6-font-size: 1rem;
$headings-margin-bottom: 10px;
$btn-padding-x: 0.75rem;
$btn-padding-y: 0.375rem;
$btn-font-size: 1rem;
$btn-border-width: 1px;
$btn-font-weight: 600;
$btn-line-height: 1.35;
$btn-link-disabled-color: #6c757d;
$btn-border-radius: 5px;
$input-font-size: 1rem;
$input-padding-y: 0.375rem;
$input-padding-x: 0.75rem;
$input-border-color: #bfbfbf;
$input-placeholder-color: #bdbdbd;
$input-btn-focus-color: NaN;
$input-focus-border-color: NaN;
$mtds-select-padding-y: 0.35rem;
$input-border-radius: 0;
$dropdown-font-size: 1rem;
$dropdown-link-active-bg: #1e1e51;
$dropdown-bg: #e0e0e0;
$dropdown-item-padding-x: 1rem;
$list-group-active-bg: #1e1e51;
$nav-pills-link-active-bg: #1e1e51;
$navbar-padding-y: 1.2rem;
$navbar-dark-hover-color: #ffffff;
$navbar-light-hover-color: #1e1e51;
$mtds-navbar-nav-item-padding-x: 0.5rem;
$mtds-navbar-link-active-font-weight: 700;
$pagination-padding-y: 0.25rem;
$pagination-padding-x: 0.5rem;
$pagination-color: #c4c4c4;
$pagination-hover-color: #1e1e51;
$pagination-active-color: #1e1e51;
$pagination-bg: #ffffff;
$pagination-border-color: #dddddd;
$card-spacer-y: 1rem;
$card-spacer-x: 1rem;
$card-border-color: #dddddd;
$card-bg: #fff;
$card-img-overlay-padding: 1rem;
$jumbotron-padding: 2rem;
$jumbotron-bg: #ffffff;
$progress-height: 0.625rem;
$progress-font-size: 1rem;
$progress-bg: #e0e0e0;
$progress-bar-bg: #1e1e51;
$progress-circle-border-width: 8px;
$progress-circle-animation-time: 0.5s;
$progress-circle-border-color: #ffffff;
$progress-circle-border-color-fill: #1e1e51;
$progress-circle-size: 6.125rem;
$progress-circle-steps: 100;
$alert-padding-y: 1.125rem;
$alert-padding-x: 1.5rem;
$alert-color-level: -12.5;
$toast-color: #6c757d;
$toast-padding-x: 1.25rem;
$breadcrumb-item-padding: 0.75rem;
$breadcrumb-divider-color: #D0D5DD;
$breadcrumb-active-color: #D0D5DD;
$breadcrumb-divider: ">";
$carousel-control-color: #1e1e51;
$carousel-indicator-width: 6px;
$carousel-indicator-height: 6px;
$carousel-indicator-spacer: 5px;
$carousel-indicator-active-bg: #1e1e51;
$modal-content-border-width: 1px;
$modal-inner-padding: 0.75rem;
$modal-lg: 800px;
$modal-md: 500px;
$modal-sm: 300px;
$grid-columns: 12;
$grid-gutter-width: 30px;
$navbar-light-color: rgba($primary, 0.75);
$navbar-dark-color: rgba($white, 0.75);
$pagination-hover-bg: rgba($secondary, 0.1);
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1280px,
);
$light-grey: #F2F2F2;