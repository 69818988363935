.view-units-wrapper {
  margin: 20px 0;

  .sv-unit-location-details {
    .sv-grey-regular-text {
      @include font(hind, bold);
      margin-bottom: 5px;
      font-size: 24px;
      line-height: 24px;
      color: $dark-grey-color;
      text-transform: uppercase;
    }

    .sv-black-para {
      color: $dark-grey-color;
      font-size: 19px;
      margin-bottom: 5px;
    }

    .borderless-btn {
      img {
        margin-right: 7px;
      }
    }
    .sv-view-units-directions {
      margin-bottom: 5px;
      .borderless-btn {
        @include font(hind, regular);
        font-size: 16px;
        line-height: 25px;
        color: $dark-grey-color;
      }
    }
  }

  .sv-info-icon {
    top: -5px;
    position: relative;
    cursor: pointer;
    left: 3px;
    svg {
      width: 10px;
      height: 10px;
    }
    .fa-info-circle {
      &::before {
        font-size: 10px;
        color: $dark-grey-color;
      }
    }
  }

  .sv-info-blue-icon {
    top: -5px;
    position: relative;
    cursor: pointer;
    left: 3px;
    svg {
      width: 10px;
      height: 10px;
    }
    .fa-info-circle {
      &::before {
        font-size: 10px;
        color: $dp-primary-blue;
      }
    }

    .sv-blue-info-icon {
      .fa-info-circle {
        &::before {
          color: $dp-primary-blue;
        }
      }
    }
  }

  .sv-units-details {
    margin-left: 24px;
    margin-bottom: 1rem;

    h2 {
      @include font(hind, semi-bold);
      font-size: 18px;
      color: $gray-500;
      margin-bottom: 0;
      line-height: 28px;
    }

    .title-container {
      height: 44px;
    }

    .expand-list-btn {
      @include font(hind, semi-bold);
      color: $gray-600;
      font-size: 16px;
      border: none;
      background-color: transparent;

      .plus-icon {
        font-size: 26px;
        vertical-align: middle;
        padding-left: 5px;
      }
    }

    .price-div {
      font-size: 32px;
    }
  }

  .sv-shop-store {
    margin: 15px 0;
    .sv-black-large-text {
      &:nth-child(2) {
        margin-left: 7px;
      }
    }
  }

  .sv-storage-units {
    min-height: 600px;
    max-height: 600px;
    overflow-y: auto;
  }

  .sv-community-involvement {
    margin: 50px 0;

    h3 {
      margin-bottom: 20px;
    }

    .community-img {
      object-fit: cover;
    }
  }

  .sv-about-access-storage {
    .section-heading {
      text-shadow: none;
    }

    h4 {
      @include font(hind, bold);
      font-size: 20px;
      line-height: 30px;
      text-transform: capitalize;
      color: #707070;
      margin-bottom: 10px;
    }

    a {
      color: $info;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    p {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 24px;
      color: $road-grey;
      text-shadow: none;
    }

    .sv-grey-small-text {
      opacity: 1;
      @include font(hind, bold);
    }
  }

  .sv-find-storage-content {
    padding: 1px 13px 16px 0px;

    .sv-multi-select-tabs {
      margin-top: 10px;
    }
  }

  .sv-filter-by-popup {
    border: 1px solid rgba(102, 102, 102, 0.3);
    margin-bottom: 20px;
    padding: 5px 14px;
    box-shadow: 3px 3px 6px rgb(102 102 102 / 30%);

    .sv-black-large-text {
      margin-bottom: 0;
      font-size: 20px;
      margin-right: 8px;
    }
  }

  .gallery {
    position: relative;
    min-height: 1px;
    padding-right: 0;
    padding-left: 0;
    height: calc(56.25vw + 24px);
    .carousel {
      position: static;
      .carousel-indicators {
        display: inline-block;
        position: absolute;
        margin-bottom: 0;
        margin-left: 10px;
        bottom: 3px;
        left: 0;
        text-align: left;
        font-size: 1px;
        width: 75%;
        z-index: 15;
        list-style: none;
        padding-left: 0;
        li {
          display: inline-block;
          text-indent: -999px;
          width: 8px;
          height: 8px;
          margin: 5px;
          border: 1px solid #fff;
          border-radius: 10px;
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0);
          &.active {
            background-color: #fff;
            border: 1px solid #fff;
          }
        }
      }

      .carousel-inner {
        position: relative;
        overflow: hidden;
        width: 100%;
        .item {
          display: none;
          position: relative;
          img {
            display: block;
            width: 100%;
            height: calc(56.25vw);
            object-fit: cover;
          }

          .videoWrapper {
            position: relative;
            display: block;
            height: 0;
            padding: 0;
            overflow: hidden;
            padding-bottom: 56.25%;
            video {
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              height: 100%;
              width: 100%;
              border: 0;
            }
          }

          &.active {
            transform: translate3d(0, 0, 0);
            left: 0;
            display: block;
          }
        }
      }

      .carousel-control {
        position: absolute;
        top: 100%;
        bottom: 0;
        font-size: 11px;
        width: 36px;
        background: #b4b4b4;
        opacity: 0.9;
        text-shadow: none;
        margin: -24px 0 0 0;
        color: white;
        &.left {
          right: 0;
          left: calc(100% - 72px);
          background-image: linear-gradient(
            to right,
            #b4b4b4 100%,
            #b4b4b4 100%
          );
          text-decoration: none;
          padding: 6px 15px;
        }
        &.right {
          right: 0;
          left: auto;
          text-decoration: none;
          padding: 6px 15px;
        }
      }
    }
  }

  .sv-client-contact-details {
    margin-top: 10px;
    .sv-dark-grey-regular-text {
      margin-bottom: 0;
    }
    .sv-client-existing-call {
      margin-left: 60px;
    }

    .sv-dark-grey-bold-para {
      margin-bottom: 0;

      a {
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          color: $dark-grey-color;
        }
      }
    }
  }

  // Below css is for View units Mobile View ------------ HTML file view-units.mobile.html
  .sv-view-units-mobile {
    .sv-breadcrumb {
      margin: 0;

      .breadcrumb {
        margin-bottom: 0;
      }
    }

    .sv-mobile-units {
      .sv-mobile-units-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      .sv-units-details {
        border-bottom: 1px solid $gray-200;
        margin-bottom: 20px;
        display: block;
        margin-left: 0px;
        h2 {
          padding-bottom: 0;
          margin-bottom: 0;
          border-bottom: 0;
          font-size: 16px;
        }
      }

      .unit-size-price {
        font-size: 32px;
        line-height: 1.3;
      }

      .action-items {
        display: flex;
        margin-bottom: 20px;
        .btn {
          display: flex;
          padding: 10px 8px 8px 8px;
          height: 44px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex: 1 0 0;
          align-self: stretch;
        }
      }

      .sv-dark-grey-bold-text {
        font-size: 30px;
        margin-bottom: 0;
      }

      .sv-grey-small-regular-text {
        // color: $black-grey-color;
        @include font(hind, semi-bold);
        font-size: 12px;
      }

      .sv-blue-video-btn {
        margin-left: 15px;
      }
    }

    .sv-link {
      .link {
        font-size: 12px;
        line-height: 15px;
      }
    }

    .sv-client-calls {
      margin: 14px 0;
      .btn {
        margin-bottom: 7px;
        padding: 6px 14px;
        font-size: 14px;
        line-height: 22px;
        height: 33px;
      }

      .sv-grey-small-regular-text {
        @include font(hind, bold);
        color: $road-grey;
        font-size: 15px;
        margin-bottom: 0;
      }
    }

    .sv-units-hours-tabs {
      .nav-tabs {
        display: flex;
        border-bottom: none;
        li {
          width: 50%;
          float: left;
          padding: 0 5px;
          button {
            @include font(hind, bold);
            font-size: 15px;
            color: $dp-dark-blue;
            width: 100%;
            text-align: center;
            text-transform: uppercase;
            height: 34px;
            padding: 6px;
            border: 1px solid rgba(102, 102, 102, 0.39);
            border-radius: 3px 3px 0px 0px;
            border-bottom: none;

            &.active {
              background-color: #f4f4f4;
              color: $darkest-blue-color;
              border-radius: 0;
              border-bottom: none;
              li {
                margin-left: 0;
              }
            }
          }
        }
      }

      .tab-content {
        .sv-units-tab-btn-containers {
          padding: 12px 0px;
          display: flex;
          flex-wrap: wrap;
          gap: 12px;
          // background-color: #f4f4f4;
          // border-bottom: 0.5px solid rgba(102, 102, 102, 0.3);
          .sv-units-tab-btn{
            flex: 1 1 40%; /* Grow, shrink, and set base width to 50% */
            box-sizing: border-box; /* Ensures padding and border are included in the width */
          }
          .sv-filter-by-popup {
            filter: none;
            margin-bottom: 0;
            background-color: $white;
          }
          #SvMdFilterModal {
            .modal-header {
              border: none;
            }
          }
        }
      }
    }

    #hours {
      .sv-hours-content {
        background-color: #f4f4f4;
        border-bottom: 0.5px solid rgba(102, 102, 102, 0.3);
        .para {
          color: $black-grey-color;
        }
      }
      .sv-black-bold-text {
        color: $black-grey-color;
      }

      .borderless-btn {
        &.link {
          a {
            @include font(hind, bold);
            font-size: 20px;
          }
        }
      }

      .sv-blue-direction-icon {
        margin-left: 10px;
      }
    }
  }

  .desktop-carousel,
  .gallery {
    .slider-container {
      .slider-list {
        .slide {
          img {
            object-fit: cover;
            width: 100%;
            height: 100%;
          }
        }
      }

      .carouselSlider {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
      }
    }
  }

  .player {
    .vp-target {
      display: none;
    }
  }

  .more-locations-near-me {
    .find-storage-wrapper {
      .sv-find-storage-content {
        padding: 0 15px;

        .custom-padding {
          padding: 0 0 15px 0;
        }
      }
    }

    .view-units-map {
      #locations-map {
        min-height: 705px;
        max-height: 705px;

        .expand-revert-btn {
          display: none;
        }

        .zoom-in-out {
          display: none;
        }
      }
    }

    .sv-page-content {
      padding: 0;
    }
  }

  .sv-view-units-mobile {
    .no-units-found-wrapper {
      .view-units-map {
        .mapAction-btn.zoom-in-out {
          display: none;
        }
      }
    }
  }
}

.h-10 {
  height: 1.5rem;
}

.locator-google-map {
  width: 100% !important;
  height: 170px !important;
}

.sv-size-help-guide {
  .sv-filter-type-container{
    .form-check{
      .label-container {
        .sv-checkbox-checked-label {
          // color: $dp-primary-blue !important;
          @include font(hind, semi-bold)
        }
      }

    }
  }
}


// .sv-units-grey-tab {
//   background-color: #f4f4f4;
//   border-bottom: 0.5px solid rgba(102, 102, 102, 0.3);
// }

.sticky_navigation {
  .sv-landing-mobile-view {
    .tab-content {
      .sv-page-content {
        padding-top: 20px;
      }
    }
  }
}

#locations-map {
  .gm-style-iw-d {
    overflow: hidden !important;
  }

  .gm-style-iw-c {
    padding: 0;
  }

  .gm-style-iw-tc {
    display: none;
  }

  .slider-frame {
    border-radius: 0;
  }

  .slider-container {
    .slider-control-centerright {
      .arrowBtn {
        padding: 0;
      }
    }
  }
}

.sv-mobile-callback-btn {
  width: 75px;
}

.sv-mobile-units-mb {
  margin-bottom: 40px;
}