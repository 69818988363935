.current-specials {
  margin: 50px 0;

  .sv-solution-card {
    .card-body {
      padding-bottom: 10px;

      .card-title {
        line-height: 26px;
      }

      .card-text {
        height: 100%;

        p {
          @include font(hind, regular);
          font-size: 16px;
          line-height: 24px;
        }
      }

      .card-img {
        height: 168px;
      }
    }
  }
}

.deals-detail-wrapper {
  .image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .deal-description {
    margin: 20px 0;

    .main-heading {
      @include font(hind, medium);
      font-size: 32px;
      line-height: 60px;
      color: $road-grey;
      text-transform: capitalize;
      text-align: center;
      position: relative;
      margin-bottom: 35px;

      &::after {
        content: "";
        position: absolute;
        background-color: $dp-primary-green;
        max-width: 94px;
        height: 1px;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
      }
    }

    img {
      max-width: 100%;
      height: 100%;
      margin-bottom: 20px;
    }
  }

  .link {
    text-decoration: none;
  }

  .pNote {
    font-size: 12px;
    line-height: 18px;
  }

  .points-list {
    li {
      @include font(hind, regular);
      font-size: 16px;
      color: $road-grey;
      line-height: 18px;
    }
  }

  h1,
  h4 {
    color: $road-grey;
    text-shadow: none;
    margin-bottom: 20px;
  }
}

.deals-info-wrapper {
  .deal-info {
    margin: 50px 0;

    .sv-storage-plan-card {
      .card-header {
        .storage-size-values {
          display: block;
          text-align: center;

          .sv-grey-bold-text {
            font-size: 22px;
          }

          .sv-blue-bold-text {
            font-size: 36px;
          }
        }
        .sv-grey-small-text {
          text-align: center;
        }
      }
    }
  }
}
