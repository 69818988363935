@import '../abstracts/figmaVariables';
@media (min-width: 376px) and (max-width: 573px) {
  .mobile-loc-cards {
    .css-21y1em {
      margin-top: 10px;
    }
  }
}

@media (min-width: 573px) {
  .mobile-loc-cards {
    .css-17yii7 {
      width: 100%;
      height: 100%;
    }
  }
}

@media (min-width: 576px) {
  .mobile-loc-cards {
    .css-17yii7 {
      width: 100%;
      height: 100%;
    }

    .slider-container {
      .slide {
        img {
          border-radius: 0 !important;
        }
      }
    }

    .css-21y1em {
      margin-top: 0;

      .css-1bgw905-MuiButtonBase-root-MuiButton-root {
        padding: 6px;
      }

      .css-2xfaq2-MuiTypography-root-MuiLink-root {
        margin-left: 12px;
      }
    }
  }
}

@media (min-width: 577px) and (max-width: 767.9px) {
  .sv-landing-mobile-view,
  .city-landing-wrapper {
    .find-storage-wrapper {
      .custom-section {
        max-width: 540px;
        padding: 0 15px;

        .px-custom,
        .sm-px-0 {
          padding: 0;
        }
      }
    }
  }

  #mobile-only {
    .locationPin {
      display: none;
    }
  }
}

@media (min-width: 768px) {
  .find-storage-wrapper .sv-find-storage-content .sv-card-title {
    font-size: 24px;
  }
  #mobile-only {
    .left-menu {
      display: block;
    }
  }

  .mobile-loc-cards {
    .sv-location-card-view-btn {
      .css-qoldu4 {
        justify-content: flex-end;
      }
    }

    .sv-primary-red-btn {
      &:hover {
        background-color: $dp-primary-green;
      }
    }
  }
}

@media (min-width: 992px) {
  .find-storage-wrapper .sv-find-storage-content .sv-card-title {
    font-size: 32px;
    line-height: 32px;
  }
  .scroll {
    #search-navbar {
      .navbar-brand {
        img {
          width: 130px !important;
          max-width: 130px !important;
          height: 60px !important;
        }
      }
    }
  }

  .desktop-carousel {
    .slider-container {
      height: 285px;
    }
  }

  .locator-google-map {
    height: 138px !important;
  }

  .visible-md {
    flex-direction: row !important;
  }

  .view-units-wrapper {
    .sv-units-details {
      margin-top: 0 !important;
    }
  }

  .more-locations-near-me {
    .find-storage-wrapper {
      .custom-section {
        padding: 0 !important;
      }
    }
  }

  .no-units-found-wrapper {
    .view-units-map {
      min-height: 100%;
      #locations-map {
        max-width: 42.5vw;
        padding-bottom: 0;
      }
    }
  }

  .city-landing-wrapper {
    .find-storage-wrapper {
      .view-units-map {
        min-height: 100%;

        #locations-map {
          min-height: 770px;
          max-height: 770px;
        }
      }
    }

    .sv-storage-units {
      min-height: 580px;
      max-height: 580px;
    }
  }
}

@media (min-width: 1200px) {
  .custom-container {
    max-width: 1280px;
  }

  .desktop-carousel {
    .slider-container {
      height: 336px;
    }
  }

  .locator-google-map {
    height: 190px !important;
  }

  .community {
    .com-info {
      .about-com {
        .about-com-article {
          max-width: calc(1170px / 2);
        }
      }
    }
  }

  .city-landing-wrapper {
    .find-storage-wrapper {
      .view-units-map {
        min-height: 100%;

        #locations-map {
          min-height: 790px;
          max-height: 790px;
        }
      }
    }

    .sv-storage-units {
      min-height: 600px;
      max-height: 600px;
    }
  }
}

@media (min-width: 1400px) {
  .view-units-wrapper {
    .more-locations-near-me {
      .find-storage-wrapper {
        .view-units-map {
          min-height: 100%;

          #locations-map {
            min-height: 705px;
            max-height: 705px;
          }
        }
      }

      .sv-storage-units {
        min-height: 600px;
        max-height: 600px;
      }
    }
  }
}

@media (min-width: 1920px) {
  .no-units-found-wrapper {
    .view-units-map {
      #locations-map {
        max-width: 45vw;
      }
    }
  }
}

@media (max-width: 1200px) {
  .french-language {
    .find-unit-size {
      .unit-sizes {
        .details {
          .size-panel {
            .sv-grey-small-text {
              height: 145px;
            }

            .sv-grey-regular-text {
              height: 50px;
            }
          }
        }
      }
    }

    .overlay-info {
      .title {
        text-transform: none;
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .your-community-section {
    .steps-card {
      .card-body {
        .card-title {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          height: 56px;
        }
      }
    }
  }
}

@media (min-width: 993px) and (max-width: 1030px) {
  .packing-supplies-wrapper {
    .storage-loc {
      .image-overlay-info {
        .overlay-img {
          height: 500px;
          object-fit: cover;
        }
      }
    }
  }
}

@media (max-width: 993px) {
  .our-brands {
    .brand-logo {
      .brand-image {
        max-height: 32px;
      }
    }
  }
}

/*----------Tablet-------*/
@media (max-width: 991.9px) {
  .navbar-brand {
    img {
      max-width: 120px;
    }
  }

  .sv-view-unit-map-details {
    .css-4o1ogd {
      overflow: hidden;
      min-height: 181px;
      max-height: 1100px;
    }
  }

  .view-units-map {
    .sv-view-unit-map-details {
      .sv-see-all-results-btn {
        padding: 3px 12px;
      }
    }
  }

  .mobile-loc-cards {
    .sv-location-card-view-btn {
      .sv-primary-red-btn {
        padding: 4px 9px;
        line-height: 20px;
        height: auto;
        width: 100%;
      }

      .css-qoldu4 {
        justify-content: flex-end;
      }
    }
  }

  .french-language {
    .mobile-loc-cards {
      .sv-location-card-view-btn {
        .sv-primary-red-btn {
          padding: 4px 6px;
        }

        .css-qoldu4 {
          justify-content: flex-end;
        }
      }
    }
  }

  .contact-us-wrapper {
    margin: 30px 0;
  }

  .sv-community-involvement {
    margin-bottom: 50px !important;

    .card-img-top {
      height: 100%;
    }
  }

  .city-landing-wrapper {
    .sv-page-content {
      padding-top: 0 !important;
    }
  }

  .more-locations-near-me {
    .sv-page-content {
      padding-top: 0;
    }

    .tab-view-search-section {
      > div {
        padding: 0;
      }
    }

    .find-storage-wrapper {
      .sv-find-storage-content,
      .sv-multi-select-tabs {
        padding: 10px 15px;
      }

      .custom-section {
        padding: 0 15px;
        max-width: 720px;
      }
    }
  }

  .sv-view-units-mobile {
    .no-units-found-wrapper {
      .view-units-map {
        .mapAction-btn {
          &.zoom-in-out {
            display: none;
          }
        }
      }
    }
  }

  .guide-card {
    height: 100%;

    .card-body {
      .card-text {
        display: -webkit-box;
        -webkit-line-clamp: unset;
        -webkit-box-orient: vertical;
        overflow: unset;
      }
    }
  }

  .sv-explore-portable-storage {
    .explore-ps-image {
      height: auto;
    }
  }

  .sv-moving-storage-cards {
    .sv-info-card {
      .card-header {
        .card-title {
          height: auto;
        }
      }
    }
  }

  .sv-portable-storage-pricing {
    .btn {
      margin-top: 15px;
    }
  }

  .reverse-flex {
    flex-direction: column-reverse;
  }

  .scroll {
    #search-navbar {
      padding: 18px 0;
      height: 95px;

      .navbar-brand {
        img {
          width: 105px;
          height: 50px;
        }
      }
    }
  }

  .small-business-wrapper {
    .sv-page-content {
      padding-top: 0;
    }
  }

  .scroll {
    #mobile-only {
      display: none;
    }

    #search-navbar {
      .search-bar {
        width: 100%;
        margin-left: 0;
      }

      .phone-link {
        display: none;
      }

      .phoneIcon {
        display: block;
      }
    }
  }

  #mobile-only {
    display: block;
    height: 95px;

    .locationPin {
      display: block;
    }

    .mobile-no {
      display: none;
    }

    .small-phone {
      display: none;
    }

    .mobile-time {
      display: none;
    }
  }

  .navbar-nav {
    .nav-link {
      .location-pin-img {
        display: none;
      }
    }
  }

  .french-language {
    .navbar-nav {
      .dropdown-menu {
        min-width: 100%;
        padding: 0;

        .dropdown-item {
          white-space: normal;
        }
      }
    }
  }

  .hero-section {
    .hero-banner {
      height: 460px;

      .search-section {
        .search-bar {
          width: 500px;
        }
      }
    }
  }

  .unit-sizes {
    .scale-card {
      height: 40px;
      img {
        height: 40px;
      }
    }
  }

  .sv-solution-card {
    .card-body {
      padding-right: 15px;
    }
  }

  .sv-review-card {
    .card-body {
      .para {
        display: -webkit-box;
        -webkit-line-clamp: 9;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .section-heading {
    font-size: 32px;
    line-height: 51px;
    margin-bottom: 40px;
  }

  .safe-storage {
    .sv-solution-card {
      &:last-child {
        margin-bottom: 0;
      }

      .card-body {
        padding-right: 20px;
      }
    }
  }

  .find-unit-size {
    .unit-sizes {
      .details {
        .size-panel {
          .sv-grey-small-text {
            font-size: 13px;
            height: 90px;
          }

          .sv-grey-regular-text {
            height: 48px;
          }

          .note {
            font-size: 13px;
            height: 40px;
          }
        }
      }
    }
  }

  .free-rentals {
    .rental-info {
      .overlay {
        padding-top: 44px;
        padding-bottom: 30px;

        .heading {
          font-size: 30px;
          line-height: 36px;
        }

        .info {
          font-size: 18px;
          line-height: 26px;
          margin: 18px 0;
        }
      }
    }
  }

  .community {
    .com-info {
      .about-com {
        .about-com-article {
          padding: 20px 30px 20px 30px;
          max-width: 565px;
        }

        .para,
        p {
          font-size: 18px;
          line-height: 29px;
          margin: 20px 0;
        }
      }
    }

    .about-section-sm {
      padding: 20px 0;

      .md-para {
        @include font(hind, regular);
        font-size: 16px;
        line-height: 24px;
        color: $road-grey;
      }

      .dp-primary-btn {
        width: 100%;
      }
    }
  }

  .image-overlay-info {
    .overlay-info {
      padding: 30px 0;

      .heading {
        font-size: 30px;
        line-height: 36px;
        margin-bottom: 0;
      }

      .info {
        font-size: 18px;
        line-height: 26px;
        margin: 15px 0;
      }

      h5 {
        line-height: 24px;
        margin-bottom: 15px;
      }
    }
  }

  .sv-residential-feature-post {
    .image-overlay-info {
      height: 220px;
    }
  }

  .partners {
    .our-brands {
      .brands-list {
        .brand-logo {
          img {
            max-width: 100%;
          }

          margin-right: 10px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }

  .sv-white-para {
    font-size: 18px;
    line-height: 28px;
  }

  .sv-our-mission {
    .sv-mission-content {
      padding: 10px 10px 10px 45px;
    }

    .mission-img {
      height: 100%;
    }
  }

  .sv-experience-card {
    margin-bottom: 30px;
  }

  .sv-team-details {
    .sv-our-team-content {
      padding-top: 12px !important;
    }
  }

  .french-language {
    .find-unit-size {
      .unit-sizes {
        .details {
          .size-panel {
            .sv-grey-small-text {
              height: 180px;
            }
          }
        }
      }
    }

    .sv-explore-section {
      .explore-card {
        .title {
          width: 83%;
        }
      }
    }

    .special-content-wrapper {
      .student-special-title {
        h1 {
          font-size: 28px;
        }
      }

      .student-special-content {
        .hero-intro-bigger {
          p {
            font-size: 22px;
          }
        }
      }
    }
  }

  #storageSize {
    max-width: 700px;

    .square-info {
      li {
        width: 125px;
      }
    }
  }

  .sizeGuide-modal {
    margin: auto;
  }

  #unitVideoModal {
    &.modal-dialog {
      iframe {
        width: 650px;
        height: 400px;
      }
    }
  }

  .sv-ss-residential-wrapper {
    .sv-solution-card {
      .card-img {
        height: 100%;
      }
    }
  }

  .sv-explore-section {
    .explore-card {
      &.image-overlay-info {
        height: 268px;

        .overlay-info {
          display: block;
        }
      }

      .title {
        font-size: 32px;
        width: 65%;
      }
    }
  }

  .sv-experience-card {
    &.sv-card-equal-height {
      height: calc(100% - 30px);
    }
  }

  .sv-select-storage-plan {
    .sv-storage-plan-card {
      .card-img {
        width: 100%;
      }
    }
  }

  .team-canada-section {
    &.hero-section {
      .hero-banner {
        .banner-content {
          .sticky-board {
            .heading-area {
              h1 {
                font-size: 46px;
                line-height: 46px;
              }
            }
          }
        }
      }
    }
  }

  .blog-card {
    height: 255px;

    .blog-thumbnail {
      height: 255px;
    }

    .card-content {
      padding: 20px;

      .card-title {
        margin: 20px 0;
      }

      .para {
        height: 90px;
        margin-bottom: 10px;
      }
    }
  }

  .team-canada-section {
    padding-top: 95px;

    &.hero-section {
      .hero-banner {
        .banner-content {
          .official-supplier {
            .supplier-logo {
              margin-top: 50px;
            }
          }
        }
      }
    }
  }

  .valet-service-wrapper {
    .sv-valet-service-locations {
      .steps-card {
        .card-top-img {
          height: 100%;
        }
      }
    }
  }

  .ss-careers {
    .sv-large-card {
      .card-info {
        padding: 28px;
      }
    }
  }

  .blog-detail-wrapper {
    .main-heading {
      font-size: 32px;
      line-height: 40px;
    }

    .blog-banner {
      img {
        height: 285px;
      }
    }
  }

  .map-search {
    padding-right: 15px;
  }

  .tab-view-search-section {
    display: block;
  }

  .special-content-wrapper {
    .student-special-title {
      padding: 15px 10px 10px 70px;

      h1 {
        font-size: 32px;
      }
    }

    .student-special-content {
      height: 415px;
    }
  }

  .swipe-Drawer {
    .mobile-loc-cards {
      display: block !important;
      overflow-x: hidden;
    }
    .css-1jmnfe1 {
      padding-right: 0;
      overflow-y: hidden;
    }
  }

  .mobile-loc-cards {
    .css-2xfaq2-MuiTypography-root-MuiLink-root {
      margin-left: 10px;
    }
  }

  .desktop-carousel,
  .gallery {
    .slider-container {
      .slider-control-bottomcenter {
        ul {
          .paging-item {
            height: 10px;
            width: 10px;
          }
        }
      }
    }
  }

  .mobile-loc-cards {
    .slider-container {
      .slide {
        img {
          border-radius: 0;
        }
      }
    }
  }

  .sv-multi-select-tabs {
    .sort-menu {
      display: none;
    }
  }

  .find-storage-wrapper {
    .custom-section {
      max-width: 100%;
      padding: 0;
    }

    &.no-units-found-wrapper {
      .view-units-map {
        #locations-map {
          min-height: calc(100vh - 95px - 131px );
          height: 100% !important;
        }
      }
    }
  }

  .no-units-found-wrapper {
    .sv-find-storage-content {
      padding: 16px 0;
    }

    .view-units-map {
      .gm-bundled-control {
        display: none;
      }

      .expand-revert-btn {
        display: none;
      }

      .mapAction-btn {
        top: 10px;

        &.zoom-in-out {
          display: block;
        }

        &.minimize-map {
          top: 60px;
          right: 10px;
        }
      }

      #locations-map {
        max-width: 100%;
      }
    }
  }

  .city-landing-wrapper {
    .find-storage-wrapper {
      .view-units-map {
        .zoom-in-out {
          display: none;
        }

        .search-move-btn {
          left: 40%;
        }
      }
    }
  }

  .view-units-wrapper {
    .sv-unit-location-details {
      height: 100%;

      .sv-grey-regular-text {
        font-size: 17px;
        margin-bottom: 0;
      }

      .sv-black-para {
        font-size: 16px;
        margin-bottom: 0;
      }
    }

    .view-units-store-img {
      height: 275px;
    }

    .sv-shop-store {
      justify-content: center;
    }

    .sv-blue-content {
      margin-top: 20px;
    }

    .sv-view-units-directions {
      .rate {
        label {
          font-size: 15px;
        }
      }
    }

    .fullSize-help {
      display: none;
    }

    .sv-client-contact-details {
      .sv-dark-grey-regular-text {
        font-size: 15px;
        line-height: 24px;
      }
    }

    .sv-view-units-web-tab {
      .desktop-carousel {
        .slider-container {
          height: 218px;

          .slider-list {
            .slide {
              img {
                object-fit: cover;
                width: 100%;
              }
            }
          }

          .slider-control-bottomcenter {
            padding: 6px;
          }
        }
      }
    }

    .sv-units-details {
      margin-top: 20px;

      h2 {
        font-size: 26px;
      }
    }

    .sv-community-involvement {
      h3 {
        margin-bottom: 8px;
      }
    }

    .more-locations-near-me {
      .find-storage-wrapper {
        .sv-find-storage-content {
          padding: 0;
        }

        .sv-multi-select-tabs {
          padding: 15px 0 0 0;
        }

        .view-units-map {
          #locations-map {
            min-height: 763px;
            height: 763px;
          }
        }
      }
    }
  }

  .locator-google-map {
    height: 90px !important;
  }

  .sv-blue-content {
    height: 100%;
  }

  .item-phone {
    flex-basis: calc(30% - 70px);
  }

  .special-content-wrapper {
    .pageContent {
      margin: 55px 0 0 0;
    }
  }

  .our-brands {
    .brand-logo {
      .brand-image {
        max-height: 20px;
      }
    }
  }

  .city-landing-wrapper {
    .custom-section {
      max-width: 720px;
      padding: 0 15px;
    }

    .sm-px-0 {
      padding: 0;
    }

    .px-custom {
      padding: 0;
    }
  }

  .about-us-wrapper {
    .sv-experience-commitment {
      .sv-experience-card {
        .cardImg {
          max-height: 102px;
        }
      }
    }
  }

  .slider-layout {
    .Scontent {
      .text-box {
        width: 20%;
      }

      .slide-area {
        width: 80%;
      }
    }
  }

  .community-giving-wrapper {
    .passionate-section {
      .sv-solution-card {
        .card-body {
          .card-title {
            font-size: 17px;
          }
        }
      }
    }
  }

  .sv-filter-container {
    gap: 18px;
    .sv-size-help-guide{
      // background-color: $base-white;
    }
    
  }
}

@media (min-width: 825px) and (max-width: 991.5px) {
  .community {
    .com-info {
      .about-com {
        .about-com-article {
          padding: 20px 30px 20px 30px;
          max-width: calc(750px / 2);
        }
      }
    }
  }
}

@media (min-width: 769px) and (max-width: 824px) {
  .community {
    .com-info {
      .about-com {
        .about-com-article {
          padding: 40px 30px 40px 44px;
        }
      }
    }
  }

  .sv-added-convenience-img {
    width: 720px !important;
    height: 223px;
  }
}

@media (max-width: 768px) {
  .community {
    .com-info {
      .about-com {
        .about-com-article {
          padding: 20px 30px 20px 45px;

          .heading {
            margin-bottom: 0;
          }
        }
      }

      .image-column {
        height: 400px;
      }
    }
  }

  .sv-read-more-expand {
    margin-bottom: 0;
    max-height: 100%;
  }

  .contact-us-wrapper {
    .sv-contact-us-details {
      .sv-reservation-hours {
        margin-top: 0;
      }
    }
  }

  #unitVideoModal {
    &.modal-dialog {
      iframe {
        width: 600px;
        height: 340px;
      }
    }
  }

  .reverse-flex-column {
    flex-direction: column-reverse;
  }

  .sv-ss-residential-wrapper {
    .ss-client-care-top-priority {
      text-align: start;
    }
  }

  .sv-team-responsive-details {
    .sv-team-reverse-columns {
      &:nth-child(2) {
        flex-direction: row-reverse;
      }
    }
  }

  .order-seq {
    order: -1;
  }

  .sv-our-mission {
    .sv-mission-content {
      .sv-white-para {
        p {
          @include font(hind, regular);
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  .sv-experience-card {
    margin-bottom: 13px !important;

    &.sv-card-equal-height {
      height: calc(100% - 13px);
    }
  }

  #myAccountModal {
    .modal-dialog {
      width: auto;
      max-width: 100%;
    }
  }

  .hero-section {
    h1 {
      font-size: 60px;
      line-height: 64px;
    }

    h6 {
      font-size: 16px;
      line-height: 26px;
      letter-spacing: 0.2em;
    }

    .promotion-banner {
      p {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  .sv-community-involvement {
    margin-bottom: 25px !important;
    margin-top: 0;

    h3 {
      font-size: 16px;
      color: $road-grey;
    }
  }

  .desktop-carousel,
  .gallery {
    height: 217px;
  }

  .view-units-wrapper {
    .gallery {
      height: calc(56.25vw + 0px);

      .slider-container {
        .slider-control-bottomcenter {
          background-color: transparent;
        }
      }
    }
  }

  .city-landing-wrapper {
    .about-access-storage {
      figure {
        &.media {
          width: 100% !important;
        }
      }

      .information {
        .image-style-align-left,
        img.image-style-align-left {
          width: 100% !important;
        }
      }
    }
  }

  // .sv-units-hours-tabs {
  //   .sv-filter-popups-grey {
  //     display: flex;
  //     justify-content: space-between;
  //     gap: 12px;
  //     // flex-direction: row-reverse;

  //     .sv-filter-by-popup {
  //       width: 50%;

  //       .sv-black-large-text {
  //         white-space: nowrap;
  //       }

  //       &.sv-responsive-size-guide {
  //         margin-left: 7px;
  //       }
  //     }
  //   }
  // }

  .ss-careers {
    .sv-large-card {
      .card-info {
        padding: 12px;
      }
    }
  }

  .benefits-of-valet-service {
    .sv-solution-card {
      img {
        object-fit: cover;
      }
    }
  }

  .sv-solution-card {
    .card-body {
      .card-title {
        font-size: 19px;
      }
    }
  }

  .blog-detail-wrapper {
    margin: 25px 0;
  }

  .TnC-wrapper {
    .user-agreement {
      margin: 33px 0;
    }
  }

  .storage-locations {
    margin: 40px 0;
  }

  .sv-multi-select-tabs {
    .sv-divider-line {
      display: none;
    }
  }
}

@media (max-width: 767.5px) {
  .sv-team-responsive-details {
    .sv-team-reverse-columns {
      &:nth-child(2) {
        flex-direction: column-reverse;
      }
    }
  }

  .no-units-found-wrapper {
    .view-units-map {
      .search-move-btn {
        display: none;
      }
    }
  }
}

@media (max-width: 767px) {
  .sv-team-details {
    .sv-team-reverse-columns {
      &:nth-child(odd) {
        flex-direction: column-reverse;
      }
    }
  }

  .sv-team-responsive-details {
    .sv-team-reverse-columns {
      &:nth-child(2) {
        flex-direction: column-reverse;
      }
    }
  }

  #mobile-only {
    .navbar-toggler {
      margin-left: 0;
    }

    .mobile-no {
      margin-top: 7px;
      display: block;
    }
  }

  .sv-tab-location-details {
    .MuiPaper-root {
      position: absolute;
      border-radius: 8px !important;
    }
  }
}

@media (min-width: 577px) and (max-width: 767.5px) {
  .scroll {
    #search-navbar {
      padding: 30px 0;
    }
  }

  .special-content-wrapper {
    .pageContent {
      margin: 456px 0 0 0;
    }
  }

  .sv-explore-section {
    .blue-column,
    .red-column {
      padding: 0;
    }

    .red-column {
      margin-top: 15px;
    }
  }

  .view-units-wrapper {
    &.sv-page-content {
      padding-top: 76px;
    }

    .sv-blue-content {
      margin-top: 0;

      .sv-white-bold-text {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  .sv-team-details {
    .sv-team-reverse-columns {
      &:nth-child(odd) {
        flex-direction: column-reverse;
      }
    }
  }

  .sv-team-responsive-details {
    .sv-team-reverse-columns {
      &:nth-child(odd) {
        flex-direction: column-reverse;
      }
    }
  }

  .hidden-xxs,
  .hidden-md {
    display: none;
  }

  .community {
    .com-info {
      .about-com {
        .about-com-article {
          padding: 20px 30px 20px 65px;
        }
      }
    }
  }

  .find-unit-size {
    .video-card {
      .styles_video__xGKVK {
        width: 500px;
        height: 315px;
        background-color: transparent;
      }
    }
  }

  .our-mission-columns {
    flex-direction: column-reverse;

    .order-sequence {
      display: contents;
      order: -1;
    }

    .sv-mission-content {
      background-color: $white;

      .sv-white-para {
        p {
          color: $road-grey;
        }
      }
    }
  }

  .sv-community-involvement {
    text-align: center;
  }

  .hero-section {
    .hero-banner {
      height: 310px;
    }

    .banner-content {
      h1 {
        font-size: 36px;
        line-height: 40px;
      }
    }
  }

  .team-canada-section {
    &.hero-section {
      .hero-banner {
        .banner-content {
          .official-supplier {
            .supplier-logo {
              margin-top: 100px;
            }
          }
        }
      }
    }
  }

  #mobile-only {
    .locationPin {
      display: block;
    }

    .mobile-no {
      display: block;
      margin-left: 15px !important;
    }
  }
}

@media (max-width: 577px) {
  .sv-landing-mobile-view {
    .sm-px-0 {
      padding: 0;
    }
  }

  .city-landing-wrapper {
    .custom-section {
      max-width: 540px;
    }
  }
  .right-menu {
    .navbar-nav {
      .dp-primary-header-btn {
        display: none!important;
      }
    }
  }
  
  .promotion-banner-general {
    min-height: 33px;
    p {
      @include font(hind, semi-bold);
      font-size: 12px;
      line-height: unset;
      padding: 2px 0;
    }
    img {
      max-height: 33px;
    }
  }
}

/*----------small mobile-------*/
@media (max-width: 576px) {
  .sv-header .leftSide-nav .navbar-brand {
    margin-right: 20px;
    max-width: 90px;
    img {
      max-height: 40px;
      max-width: 90px;
    }
  }

  .official-brand {
    img {
      max-width: 45px;
    }
  }

  .mobile-loc-cards {
    .slider-container {
      .slide {
        img {
          border-radius: 11.5px 11.5px 11px 11px;
        }
      }
    }

    .para {
      line-height: 20px;
    }
  }

  .sv-team-details {
    .sv-team-reverse-columns {
      &:nth-child(odd) {
        flex-direction: column-reverse;

        .order-seq {
          order: -1;
        }
      }

      .sv-our-team-content {
        padding-top: 24px !important;
      }
    }
  }

  .special-content-wrapper {
    .pageContent {
      margin: 380px 0 0 0;
    }

    .student-special-content {
      .hero-intro-bigger {
        margin-top: -25px;
        margin-bottom: 40px;
        font-size: 20px;

        p {
          font-size: 20px;
        }
      }
    }
  }

  #contestContent {
    margin-top: -80px;
  }

  .sv-special-content-offer {
    width: 100%;
  }

  .sv-small-media {
    display: none;
  }

  .sv-explore-section {
    .blue-column,
    .red-column {
      padding: 0;
    }

    .red-column {
      margin-top: 15px;
    }

    .image-overlay-info {
      .overlay-img {
        height: 100%;
      }
    }
  }

  .sv-wrapper {
    margin-top: 70px;
  }

  .sidenav {
    top: 70px;
    width: 100%;
  }

  #overlay-for-menu {
    top: 70px;
  }

  .hero-section {
    .hero-banner {
      height: 310px;

      .search-section {
        margin-top: 10px;

        .search-bar {
          width: 100%;
          margin: 0;
        }
      }
    }

    .banner-content {
      h1 {
        font-size: 36px;
        line-height: 40px;
      }

      h6 {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2em;
      }
    }

    .promotion-banner {
      padding: 10px 7px;

      p {
        font-size: 14px;
        line-height: 17px;
      }
    }
  }

  .sv-temp-icon-display {
    display: none !important;
  }

  h1 {
    font-size: 36px;
    line-height: 40px;
  }

  .section-heading {
    font-size: 24px;
    line-height: 38px;
    margin-bottom: 33px;
  }

  #myAccountModal {
    .modal-dialog {
      width: 100%;
    }
  }

  #mobile-only {
    display: block;
    padding: 0;
    height: 70px;

    .mobile-no {
      display: none;
      margin-top: 7px;
    }

    .small-phone {
      display: block;
    }

    .navbar-toggler {
      margin-left: 0;
    }

    .navbar-brand {
      .img-css {
        height: 45px;
      }
    }

    .leftSide-nav {
      .left-menu {
        .headerPhone {
          display: none;
        }

        .timing-panel {
          display: none;
        }
      }

      .navbar-brand {
        margin-right: 0;
      }
    }

    .mobile-time {
      display: block;
    }
  }

  .navbar-toggler {
    #nav-icon3 {
      width: 28px;
    }
    #nav-icon3 span {
      width: 70%;
      height: 2px;
      left: 8px;
    }
    #nav-icon3 span:nth-child(1) {
      top: calc(50% - 3px);
    }
    
    #nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
      top: calc(50% + 4px);
    }
    
    #nav-icon3 span:nth-child(4) {
      top: calc(50% + 11px);
    }
  }

  .search-bar {
    .form-control {
      padding: 6px 95px 6px 43px;

      &::placeholder {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .search-btn {
      min-width: 90px;
      font-size: 14px;
      line-height: 32px;

      &.search-units {
        display: block;
      }

      &.find-units {
        display: none;
      }
    }
  }

  .view-units-wrapper {
    .gallery {
      margin-top: 15px;
    }

    .sv-shop-store-para {
      text-align: center;
      p {
        font-size: 12px;
      }
    }

    .sv-filter-by-popup {
      background-color: $white;
      button {
        margin-left: 14px;
      }

      .sv-responsive-size-guide-img {
        display: none;
      }
    }

    .sv-mobile-units {
      background-color: $white;
    }

    .review-cards {
      .sv-review-card {
        height: auto;
      }
    }
  }

  .scroll {
    #search-navbar {
      padding: 10px 0;
      height: 70px;
      .search-bar {
        width: 100%;
      }

      .official-brand {
        display: none;
      }
    }
  }

  .sv-location-card {
    .secondary-card-btn {
      margin-left: 10px;
    }
  }

  .our-mission-columns {
    flex-direction: column-reverse;
    .order-sequence {
      display: contents;
      order: -1;
    }
  }
  .no-units-found-wrapper .view-units-map .list-toggle-btn {
    top:auto;
    bottom:30px;
    position:fixed;
    left: 50%;
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    min-width: 165px;
  }
  .footer {
    .sv-footer {
      .footer-links {
        margin-bottom: 28px;
      }

      .social-links {
        margin-top: 0;

        .social-icon {
          margin-left: 16px;
        }
      }
    }
  }

  .sv-location-card {
    .primary-card-btn,
    .secondary-card-btn {
      width: 100%;
      margin-top: 10px;
    }
  }

  .sv-breadcrumb {
    .breadcrumb {
      .breadcrumb-item {
        font-size: 9px;
        a {
          font-size: 9px;
          line-height: 15px;
        }
        &.active {
          font-size: 9px;
        }
      }
    }
  }

  .view-units-wrapper {
    .sv-link {
      font-size: 9px;
      .link {
        font-size: 9px !important;
      }
    }
  }

  .blog-card {
    height: 100%;
  }

  .sv-values-card {
    .card-body {
      text-align: left;
    }
  }

  .sv-our-mission {
    .sv-mission-content {
      background-color: $white !important;
      padding: 10px;

      .sv-white-para {
        color: $road-grey;
      }
    }
  }

  .about-us-wrapper {
    .sv-experience-commitment {
      .sv-experience-card {
        .cardImg {
          max-height: 100%;
        }

        .sv-link {
          display: none;
        }
      }
    }

    .sv-trusted-partners {
      img {
        max-width: 210px;
        height: 50px;
      }
    }
  }

  .sv-team-details {
    .team-img {
      height: 132px;
    }
  }

  .guide-card {
    .card-body {
      .card-text {
        height: 75px;
        -webkit-line-clamp: 3;
      }
    }
  }

  .order-seq {
    order: -1;
  }

  .sv-added-convenience-img {
    object-fit: contain;
    height: 100%;
  }

  .sv-residential-feature-post {
    .image-overlay-info {
      height: 280px;
    }
  }

  .hero-section {
    .packing-supplies-banner {
      &.hero-banner {
        height: 300px;
      }
    }
  }

  .storage-moving {
    .sv-info-card {
      .card-body {
        height: 100%;
      }
    }
  }

  .sv-ss-residential-wrapper {
    .ss-client-care-top-priority {
      text-align: left;
    }
  }

  .sv-ss-moving-wrapper {
    .sv-info-card {
      .card-header {
        .card-title {
          font-size: 24px;
        }
      }
    }
  }

  .canada-page-cards {
    .sv-info-card {
      margin-bottom: 0;
    }
  }

  .sv-info-card {
    margin-bottom: 50px;

    .card-header {
      .card-title {
        height: 100%;
        margin-top: 20px;
      }
    }
  }

  .sv-portable-storage-pricing {
    .btn {
      margin-top: 15px;
    }
  }

  .free-rentals {
    .rental-info {
      background-position-x: center;
    }
  }

  .packing-supplies-wrapper {
    .steps-card {
      height: auto;
      .card-img-top {
        height: 100%;
      }
    }

    .order-pick {
      .move-buddy {
        text-align: center;

        .order-online {
          .order-online-btn {
            height: 31px;
            line-height: 24px;
            border-radius: 15.5px;
            width: 100%;
          }
        }
      }
    }
  }

  .team-canada-section {
    padding-top: 70px;
  }

  .valet-service-wrapper {
    .sv-how-it-works {
      .sv-experience-card {
        .card-top-img {
          height: 100%;
        }
      }
    }
  }

  .blog-detail-wrapper {
    .blog-banner {
      img {
        height: 255px;
      }
    }
  }

  .community {
    .com-info {
      .com-img {
        height: 300px;
      }
    }
  }

  .sv-blue-content {
    height: 100%;
    margin-top: 0;
    padding: 10px 7px;
    .sv-white-bold-text {
      font-size: 13px;
      line-height: 18px;
    }
  }

  .mb-sm {
    margin-bottom: 0;
  }

  .mt-sm {
    margin-top: 0;
  }

  .partners {
    .partners-logo {
      .logo {
        width: 100%;
        height: 45px;
      }
    }
  }

  .view-units-wrapper {
    margin: 0;
    .sv-unit-location-details {
      margin-top: 10px;
      height: 100%;
    }

    .view-units-store-img {
      height: 275px;
    }

    .sv-shop-store {
      justify-content: center;
    }

    .sv-blue-content {
      height: 100%;
      margin-top: 0;
      .sv-white-bold-text {
        font-size: 14px;
        line-height: 18px;
      }
    }

    .sv-view-units-directions {
      .rate {
        label {
          font-size: 12px;
        }
      }
    }

    .reviews {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .sv-community-involvement {
      margin-bottom: 25px;
      margin-top: 0;
      text-align: center;
      h3 {
        margin: 20px 0;
        font-size: 16px;
        color: $road-grey;
      }
      .card-img-top {
        height: auto;
      }
      .para {
        text-align: center;
      }
    }

    .sv-about-access-storage {
      text-align: start;
    }

    .fullSize-help {
      display: none;
    }

    .sv-view-units-mobile {
      .sv-storage-cards-mobile {
        .section-heading {
          margin-bottom: 25px;
        }
      }

      .sv-breadcrumb {
        .breadcrumb-item {
          &:first-child {
            padding-left: 0;
          }
        }
      }

      .gallery {
        margin-top: 0;
        height: calc(56.25vw + 0px);
      }
    }

    .gallery {
      .slider-container {
        .slider-control-bottomcenter {
          padding: 6px 15px;
          background-color: transparent;

          ul {
            top: 3px !important;
          }
        }
      }
    }
  }

  .sv-units-details {
    display: none;
  }

  .sv-location-card {
    .card-body {
      text-align: center;

      .ratings {
        justify-content: center;
      }

      .primary-card-btn,
      .secondary-card-btn {
        display: block;
      }

      .secondary-card-btn {
        margin-left: 0 !important;
      }
    }
  }

  .no-units-found-wrapper {
    .view-units-map {
      .expand-revert-btn,
      .search-move-btn {
        display: none;
      }
    }
  }

  .special-content-wrapper {
    .student-special-title {
      margin: 16px 0 16px -75px;
    }

    .win-a-macbook-form {
      top: -25px;
    }
  }

  .mobile-view-search {
    display: flex;

    .filter-icon {
      display: block;
      padding-left: 10px;
    }
  }

  .no-units-btn {
    display: block;
    .btn {
      width: 100%;
      &.primary-card-btn {
        margin-bottom: 10px;
      }
    }
  }

  .swipe-Drawer {
    .css-4o1ogd {
      display: block;
    }
  }

  #unitVideoModal {
    .modal-content {
      .modal-body {
        .styles_video__xGKVK {
          height: 100%;
          width: 100%;

          .thumbnail {
            width: 100%;
            height: 100%;
          }

          .play-btn {
            position: absolute;
            z-index: 1;
            top: 75px;
            left: 135px;
          }
        }

        iframe {
          width: 350px;
          height: 206px;
        }
      }
    }
  }

  .sv-multi-select-tabs {
    display: none;
  }

  .city-landing-wrapper {
    .view-units-map {
      margin-bottom: 0;
    }

    .sv-view-unit-map-details {
      .css-4o1ogd {
        overflow: hidden;
      }
    }
  }

  .map-search {
    padding-right: 0;
  }

  .vehicle-storage-section {
    .steps-card {
      .card-img-top {
        height: 100%;
      }
    }
  }

  .previousLink {
    a {
      font-size: 9px;
    }
  }

  .french-language {
    .packing-supplies-wrapper {
      .steps-card {
        height: auto;
      }
    }

    .special-content-wrapper {
      .student-special-content {
        .student-special-title {
          h1 {
            font-size: 24px;
          }
        }
        .hero-intro-bigger {
          p {
            font-size: 16px;
          }
        }
      }
    }

    .view-units-wrapper {
      .sv-filter-by-popup {
        button {
          margin-left: 0;
        }
      }
    }
  }

  .sv-card-title {
    font-size: 24px;
  }

  .sv-distance-direction-view {
    display: none;
  }

  .sv-view-units-mobile {
    .sv-view-unit-map-details {
      .css-4o1ogd {
        max-height: 1500px;
      }
    }

    .find-storage-wrapper {
      .custom-section {
        padding: 0 15px;
        max-width: 540px;
      }
    }
  }

  .sv-city-landing-units-collapse {
    .accordion-body {
      padding: 20px;
    }
    .sv-view-unit-map-details {
      .css-4o1ogd {
        max-height: 1600px;
      }
    }
  }

  .career-wrapper {
    .career-description {
      margin: 25px 0;
    }
  }

  .team-canada-section {
    &.hero-section {
      .hero-banner {
        .banner-content {
          .official-supplier {
            .supplier-logo {
              margin-top: 90px;
            }
          }
        }
      }
    }
  }

  .sv-our-mission {
    .our-mission-columns {
      display: none;
    }

    .sv-mission-sm-content {
      display: flex;
    }
  }

  .item-phone {
    text-align: end;
  }

  .find-storage-wrapper {
    &.no-units-found-wrapper {
      .view-units-map {
        margin-bottom: 0;
        #locations-map {
          min-height: calc(100vh - 67px - 100px);
        }
      }
    }
  }

  .sv-location-card-view-btn {
    width: 100%;
    .sv-primary-red-btn {
      width: 100%;
    }
  }

  .sv-word-break-all {
    font-size: 18px;
  }

  .convenient-locations {
    margin-bottom: 0;

    .sv-location-card {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .sidenav {
    padding-left: 36px;
  }
}

@media (max-width: 575.5px) {
  .sv-location-card-view-btn {
    margin-top: 15px;
  }
}

@media (max-width: 415px) {
  .hidden-xxs {
    display: none;
  }

  .hidden-logo-xxs {
    display: none;
  }

  .navbar-toggler {
    padding-right: 0;
  }

  .item-phone {
    margin-left: 10px;
  }

  .scroll {
    #search-navbar {
      padding: 15px 0;
    }

    .item-logo {
      display: none;
    }
  }

  .brands-list-mobile {
    .logo-list {
      div {
        &.first-row {
          .logo-img {
            height: 28px;
          }
        }
      }
    }
  }

  .special-content-wrapper {
    .pageContent {
      margin: 435px 0 0 0;
    }
  }

  .our-brands {
    .brands-list {
      display: none;
    }

    .brands-list-mobile {
      display: block;
    }
  }

  .find-storage-wrapper {
    &.no-units-found-wrapper {
      .view-units-map {
        #locations-map {
          min-height: calc(100vh - 70px - 95px);
        }
      }
    }
  }

  .city-landing-wrapper {
    .find-storage-wrapper {
      &.no-units-found-wrapper {
        .view-units-map {
          #locations-map {
            min-height: calc(100vh - 70px - 95px);
          }
        }
      }
    }
  }

  .sm-0-px {
    padding: 0;
  }

  .sidenav {
    padding-left: 36px;
  }
}

@media (max-width: 413px) {
  .valet-service-wrapper {
    .sv-how-it-works {
      .sv-experience-card {
        .card-body {
          min-height: 108px;
        }
      }
    }
  }

  #unitVideoModal {
    .modal-content {
      .modal-body {
        iframe {
          width: 258px;
          height: 120px;
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .about-us-wrapper {
    .sv-trusted-partners {
      img {
        max-width: 110px;
        height: 30px;
      }
    }
  }
}

@media (max-width: 391px) {
  .special-content-wrapper {
    .pageContent {
      margin: 465px 0 0 0;
    }
  }
}

@media (max-width: 376px) {
  .our-brands {
    .brands-list {
      display: none;
    }
  }

  .brands-list-mobile {
    display: block;
    .logo-list {
      div {
        &.first-row {
          .logo-img {
            max-height: 27px;
          }
        }

        &.sec-row {
          .logo-img {
            max-height: 27px;
          }
        }
      }
    }
  }

  .sv-view-units-mobile {
    .find-storage-wrapper {
      .custom-section {
        padding: 0;
      }
    }

    .sv-view-unit-map-details {
      padding: 15px;
    }
  }

  .sv-landing-mobile-view {
    .sm-px-0 {
      padding: 0 15px;
    }
  }

  .city-landing-wrapper {
    .custom-section {
      max-width: 100%;
      padding: 0;
    }

    .sm-px-0 {
      padding: 0 15px;
    }

    .px-custom {
      padding: 0 15px;
    }

    .sv-view-unit-map-details {
      padding: 15px;
    }
  }

  .french-language {
    .special-content-wrapper {
      .pageContent {
        margin: 480px 0 0 0;
      }
    }
  }

  #unitVideoModal {
    .modal-content {
      .modal-body {
        padding-bottom: 0;
        iframe {
          width: 225px;
          height: 122px;
        }
      }
    }
  }
}

/*----------extra small mobile-------*/
@media (max-width: 375px) {
  h1 {
    font-size: 26px;
    line-height: 42px;
  }

  #mobile-only {
    .small-phone {
      display: block;
    }

    .mobile-no {
      display: none;
    }

    .locationPin {
      display: block;
    }
  }

  .sv-solution-card {
    .card-body {
      .card-text {
        display: none;
      }
    }
  }

  .mobile-view-details {
    .nav-pills {
      .nav-item {
        margin-right: 0;

        .nav-link {
          padding: 7px 2px;
        }
      }
    }
  }

  .sv-ss-residential-wrapper {
    .sv-solution-card {
      .card-title {
        margin-bottom: 20px;
      }

      .card-footer {
        display: block;
      }
    }

    .sv-safe-storage-responsive {
      .sv-solution-card {
        .card-title {
          margin-bottom: 13px;
        }
      }
    }
  }

  .about-us-wrapper {
    .sv-experience-commitment {
      .sv-experience-card {
        .card-body {
          h3 {
            line-height: 24px;
            height: 50px;
          }

          .para {
            display: none;
          }

          .sv-link {
            display: block;
          }
        }
      }
    }

    .sv-trusted-partners {
      img {
        max-width: 100px;
        height: 30px;
      }
    }
  }

  .sv-explore-section {
    .explore-card {
      .title {
        width: 75%;
      }
    }
  }

  .col-xs-12 {
    width: 100%;
  }

  .footer {
    .sv-footer {
      .social-links {
        .social-icon {
          margin-left: 10px;

          &.fb-icon {
            margin-left: 0;
          }
        }
      }
    }
  }

  .current-specials {
    .sv-solution-card {
      .card-body {
        .card-title {
          -webkit-line-clamp: 2;
          height: 50px;
        }

        .card-img {
          height: 100%;
        }
      }
    }
  }

  .valet-service-wrapper {
    .benefits-of-valet-service {
      .sv-solution-card {
        .card-body {
          .card-link {
            display: block;
          }
        }
      }
    }
  }

  .community-giving-wrapper {
    .passionate-section {
      .sv-solution-card {
        .card-body {
          .link {
            display: block;
          }
        }
      }
    }
  }

  .city-landing-wrapper {
    .vehicle-storage-section {
      .steps-card {
        .card-text {
          display: none;
        }
        .sv-link {
          display: block;
        }
      }
    }
  }

  .mobile-sv-tabs {
    .slider-container {
      .slider-list {
        .slide {
          .nav-link {
            padding: 2px 8px;
          }
        }
      }
    }
  }

  .view-units-wrapper {
    .gallery {
      .slider-container {
        .slider-control-bottomcenter {
          padding: 6px 2px;
        }
      }
    }
  }

  .pe-xs-0 {
    padding-right: 0;
  }

  .view-units-wrapper {
    .sv-filter-by-popup {
      .sv-black-large-text {
        white-space: nowrap;
      }
    }
  }

  .brands-list-mobile {
    .logo-list {
      div {
        &.first-row {
          .logo-img {
            max-height: 22px;
          }
        }

        &.sec-row {
          .logo-img {
            max-height: 25px;
          }
        }

        &.third-row {
          .logo-img {
            max-height: 35px;
          }
        }
      }
    }
  }

  .sv-modal-popups {
    .modal-dialog {
      .modal-content {
        padding: 10px;

        .modal-body {
          padding: 0;
        }

        .modal-header {
          .btn-close {
            right: 15px;
            top: 14px;
          }
        }
      }
    }
  }

  .sv-review-card {
    .card-body {
      padding: 18px;
      text-align: center;
    }
  }

  .shop-store {
    img {
      width: 100%;
      height: 100%;
    }
  }

  .special-content-wrapper {
    .student-special-title {
      &::after {
        border-width: 54px 16px 0 0;
      }

      h1 {
        font-size: 26px;
        line-height: 28px;
      }
    }

    .pageContent {
      margin: 405px 0 0 0;
    }

    .student-special-content {
      .hero-intro-bigger {
        font-size: 18px;

        p {
          font-size: 18px;
        }
      }
    }
  }

  #mc_embed_signup {
    #mc_embed_signup_scroll {
      #mc-embedded-subscribe {
        font-size: 16px;
      }
    }
  }

  .hidden-xxs {
    display: none;
  }
}

@media (max-width: 374px) {
  .team-canada-section {
    .promotion-banner {
      padding: 10px 0;
      p {
        font-size: 14px;
      }
    }
  }

  .scroll {
    #search-navbar {
      padding: 15px 0;
    }
  }
}

@media (max-width: 321px) {
  .contact-us-wrapper {
    .sv-contact-details-form {
      .sv-primary-red-btn {
        width: 100%;
      }
    }
  }

  .sv-review-card {
    .card-body {
      padding: 10px;
      .date {
        justify-content: center;
      }
    }
  }

  .sv-word-break-all {
    word-break: break-all;
    font-size: 16px;
  }

  .ss-careers {
    .sv-large-card {
      .card-info {
        text-align: left;
        button {
          width: 100%;
          border-radius: 18px;
        }
      }
    }
  }

  .hero-section {
    .banner-content {
      h1 {
        font-size: 26px;
        line-height: 28px;
      }

      h6 {
        font-size: 14px;
        line-height: 22px;
        letter-spacing: 0.2em;
      }
    }
    .promotion-banner {
      padding: 13px 8px;
      p {
        font-size: 12px;
        line-height: 15px;
      }
    }
  }

  .sv-view-unit-map-details {
    .sv-see-all-results-btn {
      width: 100%;
    }
  }

  .sv-card-titles {
    font-size: 24px;
    line-height: 28px;
  }

  .previousLink {
    margin-left: 0;
    a {
      font-size: 9px;
    }
  }

  .swipe-Drawer {
    .css-1jmnfe1 {
      padding-right: 0;
      overflow-y: hidden;
    }
  }

  .sv-breadcrumb {
    .breadcrumb {
      .breadcrumb-item {
        padding-left: 3px;
      }
    }
  }

  .sv-city-landing-units-collapse {
    .accordion {
      .accordion-header {
        .accordion-button {
          font-size: 14px;
          span {
            margin-left: 7px;
            img {
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
  }

  #unitVideoModal {
    &.modal-dialog {
      width: fit-content;
      top: 40px;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      .modal-content {
        padding: 10px 10px 0 10px;
        .modal-header {
          display: none;
        }
        .modal-body {
          margin: 0;
          padding: 0;
          max-height: 100%;
          height: 100%;
          .sv-video-poup-iframes {
            height: 100%;
          }
        }
      }
    }
  }

  .view-units-wrapper {
    .sv-blue-content {
      .sv-white-bold-text {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }

  .find-storage-wrapper {
    .sv-find-storage-content {
      padding: 11px 0;
    }
  }

  .find-storage-wrapper {
    &.no-units-found-wrapper {
      .view-units-map {
        #locations-map {
          min-height: calc(100vh - 70px - 95px);
        }
      }
    }
  }

  .about-us-wrapper {
    .hero-section {
      .promotion-banner {
        padding: 10px 45px;
      }
    }
  }

  .special-content-wrapper {
    .pageContent {
      margin: 505px 0 0 0;
    }
  }

  .french-language {
    .special-content-wrapper {
      .pageContent {
        margin: 485px 0 0 0;
      }
    }
  }

  .blog-detail-wrapper {
    .blog-banner {
      img {
        height: 184px;
      }
    }
  }

  .view-units-wrapper {
    .sv-filter-by-popup {
      padding: 5px;

      button {
        margin-left: 5px;
      }
    }
  }
}
