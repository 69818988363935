.contact-form {
  label {
    @include font(hind, medium);
    font-size: 15px;
    line-height: 24px;
    color: $road-grey;
  }

  input {
    border: 1px solid #bfbfbf;
    border-radius: 0;

    &:focus {
      border-color: #bfbfbf;
      box-shadow: none;
    }
  }

  .contact-by {
    display: inline-block;
  }
}

.switch {
  border: 1px solid $road-grey;
  border-radius: 10px;
  .btn-select-toggle {
    background-color: $road-grey;
    cursor: pointer;
  }

  .switch-light > span,
  .switch-toggle > span {
    @include font(hind, bold);
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    color: $road-grey;
  }

  .switch-light span span,
  .switch-light label,
  .switch-toggle span span,
  .switch-toggle label {
    color: #2b2b2b;
  }

  .switch-toggle a,
  .switch-light span span {
    display: none;
  }

  .switch-light {
    display: block;
    height: 32px;
    position: relative;
    overflow: visible;
    padding: 0;
    margin-left: 0;
    z-index: 0;
  }

  .switch-light * {
    box-sizing: border-box;
  }
  .switch-light a {
    display: block;
    transition: all 0.3s ease-out 0s;
  }

  .switch-light label,
  .switch-light > span {
    line-height: 33px;
    vertical-align: middle;
  }

  .switch-light label {
    font-weight: 700;
    margin-bottom: 0;
    max-width: 100%;
  }

  .switch-light input:focus ~ a,
  .switch-light input:focus + label {
    outline: 1px dotted rgb(136, 136, 136);
  }
  .switch-light input {
    position: absolute;
    opacity: 0;
    z-index: 5;
  }
  .switch-light input:checked ~ a {
    right: 0;
  }
  .switch-light > span {
    position: absolute;
    left: -100px;
    width: 100%;
    margin: 0;
    padding-right: 100px;
    text-align: left;
  }
  .switch-light > span span {
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 5;
    display: block;
    width: 50%;
    margin-left: 100px;
    text-align: center;
  }
  .switch-light > span span:last-child {
    left: 50%;
  }
  .switch-light a {
    position: absolute;
    right: 50%;
    top: 0px;
    z-index: 4;
    display: block;
    width: 50%;
    height: 100%;
    padding: 0;
  }
}

.sv-input-form {
  .form-group {
    margin-bottom: 28px;
    label {
      display: block;
    }
    .form-control {
      border-top: 0;
      border-right: 0;
      border-left: 0;
      border-bottom: 0.5px solid $road-grey;
      border-radius: 0;
      width: 100%;
      margin-top: 8px;
      &:focus {
        outline: 0;
        border-bottom: 1px solid $dp-primary-green;
        box-shadow: none;
      }
    }

    label,
    .form-control {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 24px;
      color: $road-grey;
    }
  }
}

.sv-modal-form {
  .form-group {
    margin-bottom: 20px;
    &:has(>.error-msg){
      margin-bottom: 1px;
    }
    .form-control {
      border-radius: 6px;
      border: 1px solid $light-red-color;
      &.error {
        border: 1px solid $dp-primary-green;
      }
    }
  }
}

.form-check {
  &.sv-multiselect-checkbox {
    display: flex;
    align-items: center;
    .form-check-input {
      background-color: $white;
      &:checked {
        background-color: $dp-primary-blue;
        border-color: $dp-primary-blue;
      }
    }
    .form-check-label {
      font-size: 17px;
      line-height: 29px;
      min-width: 150px;
      &.form-check-blue-label {
        color: $dp-primary-blue;
      }
    }
  }
}

// Don't remove the below css as we might need it in future

$color_1: #ccc;
$color_2: #ffc700;
$color_3: #deb217;
$color_4: #c59b08;

.rate {
  float: left;
  height: 20px;
  &:not(:checked) {
    > input {
      position: absolute;
      top: -9999px;
    }
    > label {
      float: right;
      width: 1em;
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      font-size: 20px;
      color: $color_1;
      &:before {
        content: "★ ";
      }
      &:hover {
        color: $color_3;
        ~ {
          label {
            color: $color_3;
          }
        }
      }
    }
  }
  > input {
    &:checked {
      ~ {
        label {
          color: $color_2;
          &:hover {
            color: $color_4;
            ~ {
              label {
                color: $color_4;
              }
            }
          }
        }
      }
    }
  }
}
