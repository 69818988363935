.sv-info-card {
  border: none;
  margin-bottom: 20px;
  .card-header {
    background-color: $white;
    border: none;
    text-align: center;
    padding: 0;
    .card-logo-img {
      img {
        height: 80px;
        padding-bottom: 21px;
        border-bottom: 1px solid $dp-primary-green;
      }
    }

    .card-title {
      margin-top: 40px;
      height: 70px;
    }
  }

  .card-body {
    padding: 0;
    text-align: center;
    p {
      margin-bottom: 0;
    }
    .para {
      @include font(hind, medium);
    }
  }
}

.sv-location-info-card {
  border: none;
  .card-header {
    padding: 0;
    .card-logo-img {
      img {
        width: 100%;
      }
    }
  }
  .card-body {
    padding: 0;
    .card-title {
      font-size: 20px;
      line-height: 32px;
      color: $road-grey;
    }
  }
}

.sv-location-card {
  border: none;
  margin-bottom: 30px;
  border-radius: 0;

  .card-img-top {
    border-radius: 0;
    height: 185px;
    object-fit: cover;
  }

  .card-body {
    padding: 12px 0;

    .location-name {
      @include font(hind, bold);
      font-size: 20px;
      line-height: 20px;
      text-transform: uppercase;
      color: $road-grey;
      margin-bottom: 10px;
    }

    .address {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 16px;
      color: $road-grey;
    }

    .card-text {
      @include font(hind, regular);
      font-size: 14px;
      line-height: 22px;
      color: $road-grey;
      margin: 5px 0;

      span {
        @include font(hind, bold);
        color: $navy-blue;
      }
    }
  }

  .primary-card-btn {
    &:hover {
      background-color: $dp-dark-green;
      color: $white;
    }
  }

  .secondary-card-btn {
    &:hover {
      background-color: $dp-primary-blue;
      color: $white;
    }
  }
}

.sv-solution-card {
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.3));
  background-color: $white;
  border: none;
  border-radius: 0;
  height: 100%;

  .card-body {
    padding-bottom: 10px;

    .card-title {
      @include font(hind, bold);
      font-size: 20px;
      color: $road-grey;
      text-transform: capitalize;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .card-text {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 19px;
      color: $road-grey;
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    p {
      margin-bottom: 0;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .card-img {
    border-radius: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-footer {
    padding-top: 0;
    padding-left: 12px;
    padding-right: 12px;
    background-color: transparent;
    border: none;
  }
}

.sv-review-card {
  background-color: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 0;
  width: 100%;
  height: 345px;
  margin-bottom: 20px;

  .card-body {
    padding: 18px;
    .customer-name {
      @include font(hind, bold);
      font-size: 16px;
      line-height: 24px;
      color: $road-grey;
    }

    .date {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 22px;
      color: $road-grey;
      margin: 15px 0;

      .rating-stars {
        margin-right: 5px;
      }
    }

    .para {
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0;
    }
  }
}

.sv-values-card {
  border: none;
  border-radius: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  height: 100%;
  .card-body {
    padding: 10px;
    text-align: center;
    .card-title {
      @include font(hind, bold);
      font-size: 20px;
      line-height: 24px;
      color: $road-grey;
    }
    .card-content {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 0;
      color: $road-grey;

      p {
        margin-bottom: 0;
      }
    }
  }
}

.sv-experience-card {
  border: none;
  border-radius: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  .card-body {
    padding: 12px;
  }
  .sv-link {
    display: none;
  }

  .para {
    height: 70px;
  }
}

.sv-card-equal-height {
  height: calc(100% - 20px);
}

.steps-card {
  border: none;
  border-radius: 0;
  background: $white;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  height: 100%;

  .card-img-top {
    border-radius: 0;
    height: 155px;
    object-fit: cover;
  }

  .card-img {
    border-radius: 0;
    height: 155px;
    object-fit: cover;
  }

  .card-body {
    padding: 18px 12px;
    height: auto;
    .step {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 30px;
      color: $road-grey;
      text-transform: uppercase;
    }

    .card-title {
      @include font(hind, bold);
      font-size: 20px;
      line-height: 28px;
      color: $road-grey;
      text-transform: capitalize;
      margin-bottom: 0;
    }

    .card-text {
      @include font(hind, regular);
      font-size: 16px;
      line-height: 24px;
      color: $road-grey;
      margin-bottom: 0;

      a {
        @include font(hind, regular);
        font-size: 16px;
        line-height: 24px;
        color: $dp-primary-green;
        text-decoration: none;
        &:hover {
          color: $dp-dark-green;
          text-decoration: underline;
        }
      }
    }

    p {
      margin-bottom: 0;
    }
  }
}

.sv-storage-plan-card {
  border: 1px solid rgba(112, 112, 112, 0.3);
  padding: 12px;
  border-radius: 0;
  margin-bottom: 20px;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.251));
  .card-header {
    padding: 0;
    background-color: $white;
    border-bottom: 1px solid rgba(102, 102, 102, 0.7);
    .storage-size-values {
      display: flex;
      justify-content: space-between;
    }
  }

  .card-body {
    padding: 0;
    margin-top: 19px;

    .card-img {
      border-radius: 0;
      object-fit: cover;
    }

    .sv-storage-plan-content {
      text-align: center;
      margin-top: 17px;
    }
  }

  .card-footer {
    background-color: transparent;
    padding: 0;
    text-align: center;
    border: none;
    .sv-storage-plan-contact {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 12px;
      .sv-grey-regular-text {
        margin-bottom: 0;
        margin-left: 7px;
      }
    }

    .learn-more-link {
      &:hover,
      &:focus {
        color: rgba(112, 112, 112, 0.6);
        cursor: pointer;
      }
    }
  }
}

.guide-card {
  border: none;
  border-radius: 0;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.3);
  background-color: $white;
  margin-bottom: 20px;
  height: 100%;
  // @extend .fadeInUp;
  .card-img-top {
    border-radius: 0;
  }

  .card-body {
    padding: 20px 17px 0px 20px;
    .card-title {
      @include font(hind, bold);
      font-size: 20px;
      line-height: 24px;
      color: $road-grey;
      margin-top: 20px;
      min-height: 25px;
      max-height: 50px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-transform: uppercase;
    }

    .card-text {
      @include font(hind, medium);
      font-size: 16px;
      line-height: 24px;
      color: $road-grey;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  .card-footer {
    background-color: $white;
    border: none;
    padding: 7px 20px;
  }
}

.storage-units-card {
  border: none;
  .sv-storage-units-card-content {
    padding: 0 15px;
    .sv-grey-bold-small-text {
      margin-bottom: 0;
    }
    .para {
      margin-bottom: 5px;
    }

    .sv-primary-red-btn {
      padding: 4px 12px;
      height: 31px;
    }
  }
}

.sv-dark-blue-text {
  @include font(hind, bold);
  color: $dark-blue-color;
}

#locations-map {
  .map-infoCard {
    min-height: 100%;
    background: $white;
    border: none;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5);
    border-radius: 11.5px;

    .slider-frame {
      border-radius: 11.5px 11.5px 0 0;
      height: 135px !important;
    }
  }
  .sv-storage-units-card-content {
    padding: 10px;
    .sv-grey-bold-small-text {
      line-height: 20px;
    }

    .para {
      line-height: 20px;
    }
  }
}
