.contact-us-wrapper {
  margin: 50px 0;
  .contact-form {
    margin: 50px 0;
  }

  .section-heading {
    margin-bottom: 41px;
  }

  .sv-contact-details-form {
    padding: 29px;
    border: 0.5px solid $road-grey;
    background-color: $white;
    margin-bottom: 30px;

    .sv-input-form {
      .form-control {
        padding-left: 0;
      }
    }

    .sv-primary-red-btn {
      margin-top: 20px;
      text-transform: uppercase;
    }

    .form-check {
      margin-right: 10px;
      margin-bottom: 20px;
      .form-check-input {
        background-color: $white;
        &:checked {
          background-color: $dp-primary-blue;
          border-color: $dp-primary-blue;
        }
      }
    }
  }

  .sv-contact-us-details {
    h3 {
      margin-bottom: 30px;
    }

    p {
      a {
        @include font(hind, regular);
        font-size: 16px;
        line-height: 24px;
        color: $dp-primary-green;
        text-decoration: none;
        word-break: break-all;
        &:hover {
          text-decoration: underline;
          color: $shades-of-blue;
        }
      }
    }

    .sv-reservation-hours {
      margin-top: 40px;

      .sv-reservation-day-time {
        display: flex;

        .day {
          width: 90px;
        }
      }
    }
  }
}
